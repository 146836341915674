import React from "react";
import "../App.css";
import {
  useRadio,
  Flex,
  useRadioGroup,
  VStack,
  HStack,
  Input,
} from "@chakra-ui/react";
import radioUnselected from "../img/radio-unselected.png";
import radioSelected from "../img/radio-selected.png";

const CustomRadio = ({ children, ...props }: { children: React.ReactNode }) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <label>
      <input {...input} />
      <Flex
        {...checkbox}
        cursor="pointer"
        bgImage={radioUnselected}
        bgRepeat="no-repeat"
        bgSize="15%"
        _checked={{
          bg: "transparent",
          borderColor: "transparent",
          bgImage: radioSelected,
          bgRepeat: "no-repeat",
          bgSize: "15%",
        }}
        _focus={{
          boxShadow: "none",
        }}
        width={"200px"}
        direction={"row"}
        maxHeight="10"
      >
        {children}
      </Flex>
    </label>
  );
};

const Menu = ({
  menu,
  setSelectedMenu,
  customMenu,
  setCustomMenu,
}: {
  menu: string;
  setSelectedMenu: (newMenu: string) => void;
  customMenu: string;
  setCustomMenu: (newCustomMenu: string) => void;
}) => {
  const options = ["Como de todo", "Vegetariano", "Vegano", "Celiaco", "Otro:"];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "menu",
    defaultValue: "",
    onChange: setSelectedMenu,
  });

  const group = getRootProps();

  return (
    <div>
      <VStack {...group} width="100%" spacing="-10px">
        {options.map((value) => {
          const radio = getRadioProps({ value });
          return (
            <CustomRadio key={value} {...radio}>
              <HStack
                justifyContent="flex-start"
                alignItems="center"
                width="100%"
                paddingLeft="25%"
              >
                <p className="text-menu-items">{value}</p>
              </HStack>
            </CustomRadio>
          );
        })}
        <Input
          width={"60%"}
          top="54%"
          variant="flushed"
          color="white"
          fontFamily="Nexa-Light"
          disabled={menu !== "Otro:"}
          value={customMenu}
          onChange={(e) => setCustomMenu(e.target.value)}
          maxLength={25}
        />
      </VStack>
    </div>
  );
};

export default Menu;
