import React from "react";
import "../App.css";
import { Button } from "@chakra-ui/react";

const Fotos = () => {
  return (
    <div className="fotos-container">
      <p className="text-big text-fotos">Fotos</p>
      <p className="text-small-title text-despues-de-la-fiesta">
        Despues de la fiesta
      </p>
      <Button
        className="button"
        colorScheme="brand"
        position={"absolute"}
        top="28%"
        borderRadius="50px"
        fontSize={{ base: "14pt", md: "18pt", lg: "18pt" }}
        fontWeight={"light"}
        height={"12%"}
        as="a"
        href="https://drive.google.com/drive/folders/1nTMdu_hvf_PMtINwknR56qvQ4nmIuXjR?usp=sharing"
        target="_blank"
      >
        Subi tus fotos aca
      </Button>
    </div>
  );
};

export default Fotos;
