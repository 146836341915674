import React from "react";
import "../App.css";

const Regalo = () => {
  return (
    <div className="regalo-container">
      <p className="text-big text-regalo">Regalo</p>
      <p className="text-small-title text-si-deseas">Si deseas regalarnos algo mas que</p>
      <p className="text-small-title text-presencia">tu hermosa presencia</p>
      <p className="text-small text-alias">
        ALIAS: rebeynico
      </p>
    </div>
  );
};

export default Regalo;
