import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

function SuccessModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} colorScheme="brand">
        <ModalOverlay />
        <ModalContent backgroundColor="#03301a">
          <ModalHeader
            fontFamily="IKnowAGhost"
            fontSize="40pt"
            opacity="0.3"
            fontWeight="light"
            color="#fffbeb"
            textAlign={"center"}
          >
            ¡Asistencia Confirmada!
          </ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody pb={6}></ModalBody>

          <ModalFooter>
            <Button
              onClick={onClose}
              variant="outline"
              fontFamily="Nexa-Light"
              color="white"
            >
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SuccessModal;
