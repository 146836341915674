import React from "react";
import "./App.css";
import bg from "./img/bg_cropped.png";
import Countdown from "react-countdown";
import Fiesta from "./components/fiesta";
import Musica from "./components/musica";
import Regalo from "./components/regalo";
import Fotos from "./components/fotos";
import Dresscode from "./components/dresscode";

// Renderer callback with condition
const renderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}: {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}) => {
  if (completed) {
    // Render a completed state
    return <p>Llego!</p>;
  } else {
    return (
      <div className="countdown-container">
        <div className="countdown-element-container">
          <p className="date-number-text">{days}</p>
          <p className="date-text">Dias</p>
        </div>
        <div className="countdown-element-container">
          <p className="date-number-text">{hours}</p>
          <p className="date-text">Horas</p>
        </div>
        <div className="countdown-element-container">
          <p className="date-number-text">{minutes}</p>
          <p className="date-text">Minutos</p>
        </div>
        <div className="countdown-element-container">
          <p className="date-number-text">{seconds}</p>
          <p className="date-text">Segundos</p>
        </div>
      </div>
    );
  }
};

function App() {
  return (
    <div className="background">
      <div className="container">
        <img src={bg} className="background-image" alt="logo" />
        <p className="text-big text-faltan">Faltan</p>
        <Countdown
          renderer={renderer}
          date={new Date("2024-06-09T00:00:00Z")}
        />
        <Fiesta />
        <Musica />
        <Regalo />
        <Fotos />
        <Dresscode />
      </div>
    </div>
  );
}

export default App;
