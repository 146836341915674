import { EmailIcon } from "@chakra-ui/icons";
import {
  Button,
  Card,
  CardBody,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FaUtensils } from "react-icons/fa6";
import Axios from "axios";

function NameModal({
  isOpen,
  onClose,
  menu,
  customMenu,
  onSuccessModalOpen,
}: {
  isOpen: boolean;
  onClose: () => void;
  menu: string;
  customMenu: string;
  onSuccessModalOpen: () => void;
}) {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");

  const [isSending, setIsSending] = useState(false);

  const onSend = async () => {
    setIsSending(true);
    await Axios.post("/api/rsvpInvitation", {
      name: name,
      lastName: lastName,
      menu: customMenu !== "" ? customMenu : menu,
    });
    setIsSending(false);
    onClose();
    onSuccessModalOpen();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} colorScheme="brand">
        <ModalOverlay />
        <ModalContent backgroundColor="#03301a">
          <ModalHeader
            fontFamily="IKnowAGhost"
            fontSize="50pt"
            opacity="0.3"
            fontWeight="light"
            color="#fffbeb"
            textAlign={"center"}
          >
            Tus Datos
          </ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel color="white" fontFamily="Nexa-Light">
                Nombre
              </FormLabel>
              <Input
                placeholder="Nombre"
                color="white"
                variant="flushed"
                focusBorderColor="white"
                fontFamily="Nexa"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel color="white" fontFamily="Nexa-Light">
                Apellido
              </FormLabel>
              <Input
                placeholder="Apellido"
                color="white"
                focusBorderColor="white"
                variant="flushed"
                fontFamily="Nexa"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </FormControl>
            <Card marginTop="10" backgroundColor="#054526">
              <CardBody fontFamily="Nexa" color="white">
                <HStack>
                  <FaUtensils />
                  <p>{customMenu !== "" ? customMenu : menu}</p>
                </HStack>
              </CardBody>
            </Card>
          </ModalBody>

          <ModalFooter>
            <Button
              leftIcon={<EmailIcon />}
              colorScheme="brand"
              mr={3}
              fontFamily="Nexa"
              aria-label={""}
              onClick={onSend}
              isLoading={isSending}
              isDisabled={name === "" || lastName === ""}
            >
              Confirmar Asistencia
            </Button>
            <Button
              onClick={onClose}
              variant="outline"
              fontFamily="Nexa-Light"
              color="white"
            >
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default NameModal;
