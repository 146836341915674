import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import Menu from "./menu";
import { FaArrowAltCircleRight } from "react-icons/fa";

function MenuModal({
  isOpen,
  onClose,
  menu,
  customMenu,
  setSelectedMenu,
  setCustomMenu,
  onClickNext,
}: {
  isOpen: boolean;
  onClose: () => void;
  menu: string;
  customMenu: string;
  setSelectedMenu: (newMenu: string) => void;
  setCustomMenu: (newCustomMenu: string) => void;
  onClickNext: () => void;
}) {
  console.log(`Menu is empty: ${menu === ""}`);
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} colorScheme="brand">
        <ModalOverlay />
        <ModalContent backgroundColor="#03301a">
          <ModalHeader
            fontFamily="IKnowAGhost"
            fontSize="50pt"
            opacity="0.3"
            fontWeight="light"
            color="#fffbeb"
            textAlign={"center"}
          >
            Menu
          </ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody pb={6}>
            <Menu
              menu={menu}
              setSelectedMenu={setSelectedMenu}
              customMenu={customMenu}
              setCustomMenu={setCustomMenu}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              leftIcon={<FaArrowAltCircleRight />}
              colorScheme="brand"
              mr={3}
              fontFamily="Nexa"
              aria-label={""}
              isDisabled={
                menu === "" || (menu === "Otro:" && customMenu === "")
              }
              onClick={onClickNext}
            >
              Siguiente
            </Button>
            <Button
              onClick={onClose}
              variant="outline"
              fontFamily="Nexa-Light"
              color="white"
            >
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default MenuModal;
