import React from "react";
import "../App.css";
import { Button } from "@chakra-ui/react";

const Musica = () => {
  return (
    <div className="musica-container">
      <p className="text-big text-fiesta">Musica</p>
      <p className="text-small-title text-suma-los-temas">
        Suma los temas que quieras bailar!
      </p>
      <Button
        className="button"
        colorScheme="brand"
        position={"absolute"}
        top="29%"
        borderRadius="50px"
        fontSize={{ base: "14pt", md: "18pt", lg: "18pt" }}
        fontWeight={"light"}
        height={"12%"}
        as="a"
        href="https://open.spotify.com/playlist/7y80mwwxa83PTub6Db3yZI?si=b727f5ede679456f&pt=ccd4e0914ee95c48eb199b7c14e4f745"
        target="_blank"
      >
        Link a playlist colaborativa
      </Button>
    </div>
  );
};

export default Musica;
