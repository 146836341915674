import React from "react";
import "../App.css";
import { Button } from "@chakra-ui/react";

const Fotos = () => {
  return (
    <div className="dresscode-container">
      <p className="text-big text-fotos">Dress Code</p>
      <p className="text-small-title text-formal">Formal. Venite elegante</p>
      <Button
        className="button"
        colorScheme="brand"
        position={"absolute"}
        top="32%"
        borderRadius="50px"
        fontSize={{ base: "14pt", md: "18pt", lg: "18pt" }}
        fontWeight={"light"}
        height={"12%"}
        as="a"
        target="_blank"
        href="https://pin.it/4IFR1XkhR"
      >
        Inspiracion
      </Button>
    </div>
  );
};

export default Fotos;
