import React, { useState } from "react";
import "../App.css";
import { Button, useDisclosure } from "@chakra-ui/react";
import cuadrado from "../img/cuadrado.png";
import NameModal from "./nameModal";
import MenuModal from "./menuModal";
import SuccessModal from "./successModal";

const Fiesta = () => {
  const [menu, setSelectedMenu] = useState("");
  const [customMenu, setCustomMenu] = useState("");

  const {
    isOpen: isNameModalOpen,
    onOpen: onNameModalOpen,
    onClose: onNameModalClose,
  } = useDisclosure();

  const {
    isOpen: isMenuModalOpen,
    onOpen: onMenuModalOpen,
    onClose: onMenuModalClose,
  } = useDisclosure();

  const {
    isOpen: isSuccessModalOpen,
    onOpen: onSuccessModalOpen,
    onClose: onSuccessModalClose,
  } = useDisclosure();

  const onMenuModalClickNext = () => {
    onMenuModalClose();
    onNameModalOpen();
  };

  const onChangeMenu = (newMenu: string) => {
    setCustomMenu("");
    setSelectedMenu(newMenu);
  };

  const onClose = (next: () => void) => {
    setSelectedMenu("");
    setCustomMenu("");
    next();
  };

  return (
    <>
      <MenuModal
        isOpen={isMenuModalOpen}
        onClose={() => onClose(onMenuModalClose)}
        menu={menu}
        customMenu={customMenu}
        setSelectedMenu={onChangeMenu}
        setCustomMenu={setCustomMenu}
        onClickNext={onMenuModalClickNext}
      />
      <NameModal
        isOpen={isNameModalOpen}
        onClose={() => onClose(onNameModalClose)}
        menu={menu}
        customMenu={customMenu}
        onSuccessModalOpen={onSuccessModalOpen}
      />
      <SuccessModal isOpen={isSuccessModalOpen} onClose={onSuccessModalClose} />
      <div className="fiesta-container">
        <p className="text-big text-fiesta">Fiesta</p>
        <p className="text-small-title text-fecha-y-hora">Fecha y hora</p>
        <p className="text-small text-fecha-y-hora-datos">
          08.06.24 desde las 21:00 hasta 5:00 hs
        </p>
        <Button
          className="button"
          colorScheme="brand"
          position={"absolute"}
          top="36%"
          borderRadius="50px"
          fontSize={{ base: "14pt", md: "18pt", lg: "18pt" }}
          fontWeight={"light"}
          height={"9%"}
          as="a"
          href="https://calendar.app.google/B1uSAPwiaYUYLYt57"
          target="_blank"
        >
          Agendar
        </Button>
        <p className="text-small-title text-lugar">Lugar</p>
        <p className="text-small text-salon-vivanco">Salon Vivanco:</p>
        <p className="text-small text-direccion">
          Gral. Bartolome Mitre 74, Tigre
        </p>
        <Button
          className="button"
          colorScheme="brand"
          position={"absolute"}
          top="75.5%"
          borderRadius="50px"
          fontSize={{ base: "14pt", md: "18pt", lg: "18pt" }}
          fontWeight={"light"}
          height={"9%"}
          onClick={onMenuModalOpen}
        >
          Confirmar asistencia
        </Button>
        <img src={cuadrado} className="estacionamiento-container" alt="" />
        <p className="text-small text-estacionamiento-1">
          Hay estacionamiento limitado.
        </p>
        <p className="text-small text-estacionamiento-2">
          Avisanos para calcular.
        </p>
      </div>
    </>
  );
};

export default Fiesta;
